import request from '../../libs/axios'

export function getTickets(params){
  return request({
    url: '/api/avia-tickets',
    method: 'get',
    params
  })
}

export function createTicketsById(){
  return request({
    url:'/api/avia-tickets',
    method:'get'
  })
}

export function addTickets(data){
  return request({
    url: '/api/avia-tickets',
    method: 'post',
    data,
  })
}

export function editTickets(data){
  return request({
    url: `/api/avia-tickets/${data.id}`,
    method: 'put',
    data,
  })
}

export function updateTickets(data){
  return request({
    url: `/api/avia-tickets/${data.id}`,
    method: 'put',
    data,
  })
}


export function deleteTickets(data){
  return request({
    url: `/api/avia-tickets/${data.id}`,
    method: 'delete'
  })
}

export function getTicketsById(id) {
  return request({
    url: `/api/avia-tickets/${id}`,
    method:'get',
  })
}

export function showTickets(id) {
  return request({
    url: `/api/avia-tickets/${id}`,
    method:'get',
  })
}

export function deleteAviaTicketSale(data) {
  return request({
    url: `/api/avia-ticket-sales/${data.id}`,
    method:'delete',
  })
}
