<template>
  <div class="w-100">
    <app-timeline>
      <app-timeline-item
          v-for="(country, index) in timelineCountries"
          :key="index"
          time="few minutes ago"
          variant="success"
      >
        <div class="d-flex justify-content-between">
          <h5>
            <box-icon v-if="index === 0" type='solid' name='plane-take-off'></box-icon>
            <box-icon v-else-if="index === (timelineCountries.length - 1)" name='plane-land' type='solid' ></box-icon>
            <box-icon v-else name='plane-alt' type='solid' ></box-icon>
            <span>{{country.name_ru}}</span>
          </h5>
          <b-button v-if="trashNot" variant="danger" size="sm" @click="removeCountry(index)">
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </app-timeline-item>
    </app-timeline>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'


export default {
  name: 'CountriesLine',
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    timelineCountries: {
      default() {
        return [];
      }
    },
    trashNot: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    removeCountry(index) {
      this.$emit('removeCountry', index)
    }
  }
}
</script>

<style scoped>

</style>
